<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <div>
            <h4 class="d-inline"><i class="fa fa-users"></i> All Vendor List</h4>
          </div>
          <hr class="clearfix my-4" />
          <vue-json-to-csv :labels="labels" :csv-title="'Users List'" :json-data="wtsData">
            <button class="btn btn-blue float-right">
              Download CSV
              <i class="fa fa-download"></i>
            </button>
          </vue-json-to-csv>
          <v-client-table :columns="columns" :data="wtsData" :options="options" :theme="theme" class="no-overflow"
            id="dataTable">
            <div slot="status" slot-scope="props">
              <span v-if="props.row.status == '0'" class="text-success">
                <button class="btn btn-sm btn-success w-100" @click="deactivateVendor(props.row.vid)">Active</button>
              </span>
              <span v-if="props.row.status == '1'" class="text-danger">
                <button class="btn btn-sm btn-danger w-100" @click="activateVendor(props.row.vid)">Inactive</button>
              </span>
            </div>
            <span slot="name" slot-scope="props">
              <router-link :to="{ name: 'Company', params: { id: props.row.companyid } }">{{ props.row.name }}
              </router-link>
            </span>

            <span slot="creation_date" slot-scope="props">{{
                props.row.creation_date | moment("M/D/YYYY")
            }}</span>

          </v-client-table>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import Multiselect from "vue-multiselect";
import VueJsonToCsv from "vue-json-to-csv";
Vue.use(ClientTable);

export default {
  name: "ManagerAssignWts",
  components: {
    ClientTable,
    Event,
    Multiselect,
    VueJsonToCsv,
  },
  data() {
    return {
      columns: [
        "status",
        "Name",
        "company_name",
        "creation_date",
        "vidcode",
        "gst",
      ],
      wtsData: [],
      options: {
        // headings: {
        // },
        sortable: [
          "firstName",
          "lastName",
          "email",
          "role",
          "status",
          "authority",
          "creation_date",
        ],
        filterable: [
          "firstName",
          "lastName",
          "email",
          "role",
          "status",
          "authority",
          "creation_date",
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      theme: "bootstrap4",
      template: "default",
      useVuex: false,
      fname: "",
      lname: "",
      email: "",
      status: "",
      fax: "",
      phone: "",
      password: "",
      password2: "",
      id: "",
      error: false,
      passworderror: false,
      projectData: [],
      projectid: [],
      unique: false,
      assignerror: false,
      passlimit: 0,
      wtsAssignmentData: [],
      wtsFilteredData: [],
      editprojectid: "",
      editwtsid: "",
      editstatus: "",
      editpasslimit: "",
      edit: "",
      editid: "",
      editassignerror: false,
      filteredProjectData: [],
    };
  },
  methods: {
    activateVendor(id) {
      let data = JSON.stringify({
        status: 0,
        vid: id
      })
      this.$http
        .post("/sys_admin/edit/vendor/status", data)
        .then(response => {
          this.$toasted.show('Successfully Vendor Status updated.', { type: 'success', duration: '3000' });
          this.loadData();
        })
        .catch(error => {
          this.$toasted.show("Error."+error, { type: "error", duration: "3000" });
        })
    },
    deactivateVendor(id) {
      let data = JSON.stringify({
        status: 1,
        vid: id
      })
      this.$http
        .post("/sys_admin/edit/vendor/status", data)
        .then(response => {
          this.$toasted.show('Successfully Vendor Status updated.', { type: 'success', duration: '3000' });
          this.loadData();
        })
        .catch(error => {
          this.$toasted.show("Error."+error, { type: "error", duration: "3000" });
        })
    },
    clearErrors() {
      this.error = false;
      this.assignerror = false;
      this.editassignerror = false;
      this.projectid = [];
    },
    loadData() {
      this.$http
        .get("/sys_admin/get/all_users/vendors")
        .then((response) => {
          this.wtsData = response.data.result;
        })
        .catch((error) => {
          this.$toasted.show("Error.", { type: "error", duration: "3000" });
        });
    },
  },
  mounted: function () {
    this.loadData();
    console.log("this.wtsData", this.wtsData);
  },
};
</script>

<style lang="scss">
label {
  display: block !important;
}
</style>
